import React from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
export default function ThemeToggle() {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <label className="absolute top-0 right-0 lg:relative lg:top-auto lg:right-auto lg:m-0 mt-8 mr-8 md:mr-16 flex flex-row items-center gap-4 cursor-pointer rounded-full border border-grey-medium border-opacity-40 px-6 py-3 hover:bg-grey-medium hover:bg-opacity-40 transition duration-300">
          <input
            type="checkbox"
            className="absolute left-[-100vw]"
            onChange={(e) => toggleTheme(e.target.checked ? "dark" : "light")}
            checked={theme === "dark"}
          />{" "}
          <span>
            {theme === "dark" ? (
              <div className="flex flex-row items-center gap-4">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.28564 0H10.7142V3.57143H9.28564V0Z"
                    fill="white"
                  />
                  <path
                    d="M14.062 4.92858L16.5663 2.42358L17.5763 3.4343L15.072 5.93858L14.062 4.92858Z"
                    fill="white"
                  />
                  <path
                    d="M16.4287 9.28571H20.0001V10.7143H16.4287V9.28571Z"
                    fill="white"
                  />
                  <path
                    d="M14.062 15.0714L15.072 14.0614L17.5763 16.5657L16.5663 17.5764L14.062 15.0714Z"
                    fill="white"
                  />
                  <path
                    d="M9.28564 16.4286H10.7142V20H9.28564V16.4286Z"
                    fill="white"
                  />
                  <path
                    d="M2.42432 16.5657L4.9286 14.0614L5.9386 15.0721L3.43432 17.5764L2.42432 16.5657Z"
                    fill="white"
                  />
                  <path
                    d="M0 9.28571H3.57143V10.7143H0V9.28571Z"
                    fill="white"
                  />
                  <path
                    d="M2.42432 3.4343L3.43432 2.42358L5.9386 4.92858L4.9286 5.93858L2.42432 3.4343Z"
                    fill="white"
                  />
                  <path
                    d="M10.0001 7.14287C10.5652 7.14287 11.1176 7.31043 11.5874 7.62438C12.0573 7.93833 12.4235 8.38455 12.6397 8.90663C12.856 9.4287 12.9126 10.0032 12.8023 10.5574C12.6921 11.1116 12.42 11.6207 12.0204 12.0203C11.6208 12.4199 11.1117 12.692 10.5575 12.8023C10.0032 12.9125 9.42876 12.8559 8.90669 12.6397C8.38462 12.4234 7.93839 12.0572 7.62444 11.5874C7.3105 11.1175 7.14293 10.5651 7.14293 10C7.14387 9.24254 7.4452 8.51636 7.98081 7.98075C8.51642 7.44513 9.2426 7.14381 10.0001 7.14287ZM10.0001 5.71429C9.15244 5.71429 8.32384 5.96565 7.61906 6.43657C6.91427 6.90749 6.36496 7.57683 6.04059 8.35994C5.71621 9.14305 5.63134 10.0048 5.79671 10.8361C5.96207 11.6675 6.37025 12.4311 6.96961 13.0305C7.56898 13.6298 8.33262 14.038 9.16397 14.2034C9.99532 14.3687 10.857 14.2839 11.6401 13.9595C12.4233 13.6351 13.0926 13.0858 13.5635 12.381C14.0344 11.6762 14.2858 10.8476 14.2858 10C14.2858 8.86337 13.8343 7.77328 13.0305 6.96955C12.2268 6.16582 11.1367 5.71429 10.0001 5.71429Z"
                    fill="white"
                  />
                </svg>
                <span className="hidden lg:block">Light</span>
              </div>
            ) : (
              <div className="flex flex-row items-center gap-4">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.28571 0H10.7143V3.57143H9.28571V0Z"
                    fill="#11264B"
                  />
                  <path
                    d="M14.0621 4.92858L16.5664 2.42358L17.5764 3.4343L15.0721 5.93858L14.0621 4.92858Z"
                    fill="#11264B"
                  />
                  <path
                    d="M16.4286 9.28571H20V10.7143H16.4286V9.28571Z"
                    fill="#11264B"
                  />
                  <path
                    d="M14.0621 15.0714L15.0721 14.0614L17.5764 16.5657L16.5664 17.5764L14.0621 15.0714Z"
                    fill="#11264B"
                  />
                  <path
                    d="M9.28571 16.4285H10.7143V20H9.28571V16.4285Z"
                    fill="#11264B"
                  />
                  <path
                    d="M2.42426 16.5657L4.92854 14.0614L5.93854 15.0721L3.43426 17.5764L2.42426 16.5657Z"
                    fill="#11264B"
                  />
                  <path
                    d="M0 9.28571H3.57143V10.7143H0V9.28571Z"
                    fill="#11264B"
                  />
                  <path
                    d="M2.42426 3.4343L3.43426 2.42358L5.93854 4.92858L4.92854 5.93858L2.42426 3.4343Z"
                    fill="#11264B"
                  />
                  <path
                    d="M10 5.71429C9.15238 5.71429 8.32378 5.96565 7.61899 6.43657C6.91421 6.90749 6.3649 7.57682 6.04053 8.35994C5.71615 9.14305 5.63128 10.0048 5.79665 10.8361C5.96201 11.6675 6.37018 12.4311 6.96955 13.0305C7.56892 13.6298 8.33256 14.038 9.16391 14.2034C9.99526 14.3687 10.857 14.2839 11.6401 13.9595C12.4232 13.6351 13.0925 13.0858 13.5635 12.381C14.0344 11.6762 14.2857 10.8476 14.2857 10C14.2857 8.86337 13.8342 7.77328 13.0305 6.96955C12.2267 6.16582 11.1367 5.71429 10 5.71429Z"
                    fill="#11264B"
                  />
                </svg>
                <span className="hidden lg:block">Dark</span>
              </div>
            )}
          </span>
        </label>
      )}
    </ThemeToggler>
  )
}
