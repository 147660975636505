import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import { loginUser, useAuthStatus } from "gatsby-plugin-google-gapi"
import { AiOutlineGoogle } from "react-icons/ai"

import Header from "./Header"

const duration = 0.35

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: duration,
    },
  },
}

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { authed } = useAuthStatus()

  if (authed === null || authed === false) {
    return (
      <div className="flex w-full h-screen">
        <button
          className="flex flex-row items-center gap-4 p-4 px-8 m-auto bg-blue-200 rounded-lg shadow-xl"
          onClick={loginUser}
        >
          <span>Login with</span> <AiOutlineGoogle />
        </button>
      </div>
    )
    // return <>{ loginUser } Login</>
  } else {
    return (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <AnimatePresence>
          <motion.main
            key={location.pathname}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            {children}
          </motion.main>
        </AnimatePresence>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
