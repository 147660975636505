import React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ThemeToggle from "./ThemeToggle"
// import { FaEnvelope, FaPhoneAlt } from "react-icons/fa"
import { BiArrowToTop } from "react-icons/bi"
import { AnchorLink } from "gatsby-plugin-anchor-links"


const Header = ({ siteTitle }) => (
  <header id="header" className="bg-white border-b dark:bg-secondary-dark border-grey-medium border-opacity-20">
    <div className="container px-8 py-10 md:px-16">
      <div className="flex flex-wrap items-center justify-between">
        <Link
          className="flex flex-row items-center gap-4 text-lg font-bold font-display md:text-xl text-secondary-dark dark:text-white"
          to="/"
        >
          <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30 1.13522V29.7399H25.3474L24.7862 26.2623C22.2447 28.8834 18.6995 30.4542 14.611 30.4542C6.25 30.4512 0 24.0105 0 15.4391C0 6.84345 6.25 0.454224 14.611 0.454224C18.747 0.454224 22.3278 2.04623 24.8753 4.7127L25.5493 1.13522H30ZM24.2191 15.4391C24.2191 10.0487 20.3533 6.0535 15.0653 6.0535C9.77732 6.0535 5.87292 10.0819 5.87292 15.4391C5.87292 20.7932 9.77435 24.8247 15.0653 24.8247C20.3563 24.8247 24.2191 20.8295 24.2191 15.4391Z" className="fill-current"/>
          <path d="M15 20C17.7614 20 20 17.7614 20 15C20 12.2386 17.7614 10 15 10C12.2386 10 10 12.2386 10 15C10 17.7614 12.2386 20 15 20Z" fill="#FF6B4A"/>
          </svg>

          <span className="text-primary-default">Sector Hub</span>
        </Link>

        <div className="flex flex-row items-center w-full gap-8 mt-8 md:gap-16 lg:w-auto lg:mt-0 text-2xs lg:text-sm">
          {/* <Link className="flex block text-xs font-semibold hover:text-primary-default font-display lg:text-base" activeClassName="opacity-50" to="/">
            Teams
          </Link>
          <Link className="flex block text-xs font-semibold hover:text-primary-default font-display lg:text-base" activeClassName="opacity-50" to="/disciplines/">
            Disciplines
          </Link>
          <div className="flex flex-row ml-auto lg:gap-8 xl:ml-40">
            <Link className="flex items-center block px-6 py-3 ml-auto border rounded-full border-grey-medium border-opacity-40 hover:bg-grey-medium hover:bg-opacity-40" activeClassName="opacity-50" to="/change-request/">
              <span>Request a Change</span>
            </Link>
            <div class="flex flex-row absolute lg:relative lg:top-auto lg:right-auto lg:m-0 top-0 right-0 mt-8 mr-32 md:mr-40 gap-4 px-4 py-2 items-center rounded-full border-grey-medium border-opacity-40 border">
              <span>
              <StaticImage
                src={"../images/roundworks-logo.png"}
                alt="RoundWorks IT logo"
                className="w-10 h-10 rounded-full"
              /></span>
              <a className="hover:text-primary-default focus:text-primary-default" aria-label="Call RoundWorks IT" href="tel:0333 344 4645"><FaPhoneAlt className="text-xs" /></a>
              <a className="hover:text-primary-default focus:text-primary-default" aria-label="Email RoundWorks IT" href="mailto:info@roundworks-it.co.uk"><FaEnvelope className="text-xs" /></a>
            </div> */}

            <ThemeToggle />
          {/* </div> */}
        </div>
      </div>
    </div>
    <AnchorLink
      to={"#header"}
      className="fixed bottom-0 right-0 z-10 flex flex-col items-center p-4 mb-2 mr-2 bg-white rounded-full opacity-50 dark:bg-secondary-dark hover:opacity-100 hover:bg-grey-light"
    >
      <BiArrowToTop className="text-lg" />
    </AnchorLink>

  </header>
)

export default Header
